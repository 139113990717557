<template>
  <div class="container-fluid">
    <div class="row row-login-form align-items-center justify-content-center">
      <div class="col-xs-10 col-sm-8 col-md-6">
        <loading-wrapper :isLoading="isSelectAccreditationLoading">
          <select-accreditation-form v-on:load="onSelectAccreditationFormLoaded"></select-accreditation-form>
        </loading-wrapper>
      </div>
    </div>
  </div>
</template>

<script>
import SelectAccreditationForm from '@/components/organism/SelectAccreditationForm'
import LoadingWrapper from '@/components/organism/LoadingWrapper'

export default {
  components: {
    SelectAccreditationForm,
    LoadingWrapper
  },
  data() {
    return {
      isSelectAccreditationLoading: true
    }
  },
  methods: {
    onSelectAccreditationFormLoaded: function() {
      this.isSelectAccreditationLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../utilities/config';

.row-login-form {
  min-height: 100vh;
  height: auto;
  background-color: gray('gray-100');
  padding-top: 100px;
  padding-bottom: 50px;
}
</style>
