<template>
  <div>
    <a href="/" class="m-select-accred-form__logo-link">
      <img src="@/assets/logo_zone.svg" width="187" height="69" class="m-select-accred-form__logo" />
    </a>

    <div v-if="!isSAC">
      <h3 class="m-select-accred-form__suptitle">{{ $t('selectaccreditationwelcome') }}</h3>
      <h2 class="m-select-accred-form__title">{{ $t('selectaccreditation') }}</h2>
    </div>
    <div v-else>
      <h3 class="m-select-accred-form__suptitle m-select-accred-form__suptitle--margin-bottom">{{ $t('loginSACtitle') }}</h3>
      <p class="m-select-accred-form__text">{{ $t('loginSACNotice1') }}</p>
      <p class="m-select-accred-form__text">{{ $t('loginSACNotice2') }}</p>
      <p class="m-select-accred-form__text">{{ $t('loginSACNotice3') }}</p>
      <form @submit.prevent="showAccreditationByEmail">
        <div class="row m-select-accred-form__row">
          <div class="col-12 col-md-6">
            <input-gcr
              invalidText="accRenewFormFieldRequired"
              type="email"
              v-model="emailSAC"
              :placeholder="$t('loginSACInputPlaceholder')"
            />
          </div>
          <div class="col-12 col-md-6 m-select-accred-form__submit-col">
            <button-gcr
              btnClass="btn-primary btn-sm"
              label="goToSACEmailBtn"
              :disabled="!isEmailSACValid"
              :loading="$apollo.loading"
            />
          </div>
        </div>
      </form>
    </div>
    <p v-if="showNoAccreditationError" class="m-select-accred-form__error">{{ $t('emailSACNoAccreditationError') }}</p>

    <span v-if="accreditations.length > 0">
      <!--
      <p v-if="showSACEmail">{{ $t('selectSACAccreditationText') }}</p>
      -->
      <p class="m-select-accred-form__text">{{ $t('selectaccreditationtext') }}</p>
      <div class="m-select-accred-form__btns">
        <button-accreditation
          v-for="accred in accreditations"
          :key="accred.id"
          @btnclick="select(accred.id)"
          :accrednumber="accred.number"
          :accredname="accred.company.name"
          :accredactive="accred.isActive"
        />
      </div>
    </span>
  </div>
</template>

<script>
import { Consts, Validations, User } from '@/_helpers'
import ButtonAccreditation from '@/components/atoms/ButtonAccreditation'
import InputGcr from '@/components/atoms/Input'
import ButtonGcr from '@/components/atoms/Button'
import currentUserRoles from '@/graphql/users/getCurrentUserRoles.graphql'
import getAccreditations from '@/graphql/accreditations/getAccreditations.graphql'
import getAccreditationsByEmail from '@/graphql/accreditations/getAccreditationsByEmail.graphql'

export default {
  mounted() {
    if (this.isEmailSACValid) {
      this.showAccreditationByEmail()
    }
  },
  components: { ButtonAccreditation, InputGcr, ButtonGcr },
  data() {
    return {
      showSACEmail: false,
      showNoAccreditationError: false,
      emailSAC: this.$store.getters[Consts.GETTER_USER_EMAILSAC],
      accreditations: [],
      btnLoading: false,
      currentUser: null
    }
  },
  apollo: {
    getprofile: {
      query: currentUserRoles,
      update(data) {
        this.currentUser = data.currentUser
        this.$emit('load')
      }
    },
    getaccreditations: {
      query: getAccreditations,
      skip() {
        return this.isSAC
      },
      update(data) {
        this.accreditations = data.accreditations
        this.$emit('load')
      }
    }
  },
  methods: {
    select(id) {
      this.$store.commit(Consts.ACTION_ACCREDITATION_SELECT, id)
      this.$router.push(this.$route.query.returnPath || '/')
    },
    showAccreditationByEmail() {
      this.btnLoading = true
      this.$apollo.watchQuery({
        query: getAccreditationsByEmail,
        variables: {
          email: this.emailSAC
        }
      }).forEach(({ data }) => {
        if (!data) return

        this.accreditations = data.accreditationsByEmail

        if (this.accreditations.length > 0) {
          this.showNoAccreditationError = false
          this.$store.dispatch(Consts.ACTION_USER_SETUSERSACEMAIL, this.emailSAC)
        } else {
          this.$store.dispatch(Consts.ACTION_USER_SETUSERSACEMAIL, null)
          this.showNoAccreditationError = true
        }
      }).finally(() => {
        this.btnLoading = false
      })
    }
  },
  computed: {
    isSAC() {
      if (!this.currentUser)
        return undefined
      return User.validateHaveCustomerSupport(this.currentUser.roles)
    },
    isEmailSACValid() {
      return Validations.isEmail(this.emailSAC)
    }
  }
}
</script>

<style lang="scss">
@import '../../utilities/config';
.m-select-accred-form {
  &__title {
    margin-top: 0;
    margin-bottom: 16px;
    font-weight: 500; //font-weight: 400;;
  }
  &__suptitle {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 25px;
    font-weight: 300;

    &--margin-bottom {
      margin-bottom: 16px;
    }
  }
  &__text {
    font-size: 14px;
  }
  &__logo-link {
    display: block;
    margin-bottom: 24px;
  }
  &__btns {
    margin-top: 35px;
  }
  &__row {
    margin-top: 32px;
  }
  &__submit-col {
    padding-top: 16px;
    padding-bottom: 32px;
    text-align: right;
    @media screen and (min-width: breakpoint-min('md')) {
      padding-top: 2px;
      text-align: left;
    }
  }
  &__error {
    font-weight: 400;
    color: theme-color('danger');
  }
}
</style>
