<template>
  <div class="a-input">
    <label v-if="label" :for="id">
      <span v-html="$t(label)" />
      <template v-if="optional">&nbsp;<span class="optional">{{ $t('optional') }}</span></template>
      <template v-if="tooltip">&nbsp;<info :tooltip="tooltip" @click.native.prevent/></template>
    </label>
    <input
      ref="input"
      v-if="inputmask"
      v-bind="$attrs"
      v-on="listeners"
      :value="value"
      :id="id"
      class="form-control"
      :disabled="disabled"
      :class="{ 'is-invalid': invalid }"
      v-mask="inputmask"
    />
    <input
      ref="input"
      v-if="!inputmask"
      v-bind="$attrs"
      v-on="listeners"
      :value="value"
      :id="id"
      class="form-control"
      :disabled="disabled"
      :class="{ 'is-invalid': invalid }"
    />
    <div v-show="invalid" class="invalid-feedback">{{ $t(invalidText) }}</div>
    <div v-if="showValidationFeedback" class="invalid-feedback html5-validation" v-html="validationFeedback" />
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import Info from '@/components/atoms/Info'

export default {
  components: { Info },
  inheritAttrs: false,
  directives: { mask },
  props: {
    invalidText: {},
    label: {},
    tooltip: {},
    value: {},
    numeric: Boolean,
    submitted: {},
    inputmask: {},
    optional: {
      type: Boolean,
      default() {
        return false
      }
    },
    disabled: Boolean,
    id: {
      default() {
        return `input-${this._uid}`
      }
    },
    validationFeedback: {
      type: String,
      default() {
        return ''
      }
    }
  },
  computed: {
    invalid() {
      return this.invalidText && this.submitted && !this.value
    },
    listeners() {
      return {
        ...this.$listeners,
        input: event => this.onInput(event.target)
      }
    },
    showValidationFeedback() {
      return !this.disabled && this.validationFeedback
    }
  },
  methods: {
    onInput(input) {
      this.$emit('input', this.getValue(input.value))
    },
    getValue(val) {
      if (!this.numeric) return val
      const number = parseFloat(val)
      return isNaN(number) ? null : number
    }
  },
  watch: {
    validationFeedback(message) {
      this.$refs.input.setCustomValidity(message)
    }
  },
  mounted() {
    this.$refs.input.setCustomValidity(this.validationFeedback)
  }
}
</script>

<style lang="scss">
@import '../../utilities/config';

.a-input {
  input {
    &::placeholder {
      color: gray('gray-150');
      font-weight: 300;
    }
  }
}

.invalid-feedback.html5-validation {
  display: none;
}

.was-validated .invalid-feedback.html5-validation {
  display: block;
}
</style>
