<template>
  <div>
    <slot name="loader" v-if="isLoading">
      <div class="o-loader">{{ $t('loading') }}</div>
    </slot>
    <div v-show="!isLoading"><slot></slot></div>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss">
@import '../../utilities/config';
.o-loader {
  width: 100%;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;

  &::before {
    content: '';
    display: block;
    height: 40px;
    width: 40px;
    background-image: url(../../assets/loading_gray.svg);
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 8px;
    margin-bottom: 8px;
  }
}
</style>
