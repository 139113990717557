<template>
  <button class="btn a-btn-accreditation" :class="btnClass" :disabled="disabled" v-on:click="onclick">
    <div class="a-btn-accreditation__number">{{ accrednumber }}</div>
    <div class="a-btn-accreditation__name">
      {{ accredname }}
      <span v-if="!accredactive" class="a-btn-accreditation__desactivated">{{ $t('deactivatedAccreditationLabel') }}</span>
    </div>
  </button>
</template>

<script>
export default {
  props: ['label', 'disabled', 'btnClass', 'accrednumber', 'accredname', 'accredactive'],
  methods: {
    onclick(evt) {
      this.$emit('btnclick', evt)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../utilities/config';
@import 'node_modules/bootstrap/scss/buttons';

.btn.a-btn-accreditation {
  //prevent some loading order conflict
  border-radius: 0;
  border: 1px solid gray('gray-150');
  width: 100%;
  text-align: left;
  font-size: 14px;
  color: gray('gray-700');
  font-weight: 500; //font-weight: 400;;
  padding: 13px 40px 13px 18px;
  display: flex;
  position: relative;
  background-color: gray('white');
}

.a-btn-accreditation {
  &__number {
    min-width: 65px;
    width: 65px;
    max-width: 65px;
    margin-right: 10px;
    color: gray('gray-400');
    display: flex;
  }

  &__name {
    max-width: calc(100% - 115px);
    white-space: normal;
  }

  &__desactivated {
    color: gray('gray-400');
  }

  & + & {
    border-top: 0;
  }

  &::after {
    content: '';
    color: gray('white');
    display: block;
    position: absolute;
    right: 22px;
    top: 50%;
    height: 100%;
    width: 10px;
    background-image: url(../../assets/chevron_right_dark_green.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    transform: translate(0, -50%);
    transition: right 0.25s ease;
  }

  &:hover,
  &:focus,
  &:active {
    box-shadow: inset 1px 0 0 gray('gray-150'), inset -1px 0 0 gray('gray-150'), 0 1px 2px 0 rgba(gray('gray-750'), 0.15),
      0 1px 3px 1px rgba(gray('gray-750'), 0.1);
    z-index: 1;

    .a-btn-accreditation__number {
      font-weight: 500;
    }

    &::after {
      right: 19px;
    }
  }
}
</style>
